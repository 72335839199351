import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00909c63"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "highlight-text"
}
const _hoisted_2 = { class: "plan-header" }
const _hoisted_3 = {
  key: 0,
  class: "plan-price"
}
const _hoisted_4 = { class: "currency-symbol" }
const _hoisted_5 = { class: "currency-value" }
const _hoisted_6 = { class: "currency-code" }
const _hoisted_7 = {
  key: 1,
  class: "plan-price unpriced"
}
const _hoisted_8 = {
  key: 2,
  class: "plan-billing"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "plan-checks" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 2,
  class: "warning"
}
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "button-wrapper" }
const _hoisted_16 = {
  key: 2,
  class: "trial-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(['card-wrapper', { isHighlighted: _ctx.isHighlighted }])
  }, [
    (_ctx.isHighlighted)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.t('highlight')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["plan-name", { unpriced: _ctx.theme === 'unpriced' }])
      }, _toDisplayString(_ctx.t(_ctx.snakeCase(_ctx.planType))), 3),
      _createElementVNode("div", null, [
        (_ctx.theme === 'priced')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.userCurrencySymbol), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.formatedPriceAmount), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.userCurrencyCode), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.theme === 'unpriced')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.t('free')), 1))
          : _createCommentVNode("", true),
        (_ctx.theme === 'priced')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.planBilling), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
          _createElementVNode("span", { innerHTML: _ctx.description }, null, 8, _hoisted_10)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_11, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planCheckItems, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(['plan-check-item', { 'hide-checkmark': _ctx.hideCheckmark }]),
          innerHTML: item
        }, null, 10, _hoisted_12))
      }), 128))
    ]),
    (_ctx.planType === 'freeBusiness')
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createVNode(_component_v_icon, {
            color: "#FF2424",
            size: "16px"
          }, {
            default: _withCtx(() => [
              _createTextVNode("mdi-alert-rhombus-outline")
            ]),
            _: 1
          }),
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.scrollToTable && _ctx.scrollToTable(...args)), ["prevent"])),
            innerHTML: _ctx.t('impact_price_warning')
          }, null, 8, _hoisted_14)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_15, [
      (_ctx.planType !== 'freeBusiness')
        ? (_openBlock(), _createBlock(_component_gs_button, {
            key: 0,
            type: "primary-v2",
            "full-width": "",
            size: "normal",
            disabled: _ctx.buttonLoading || _ctx.isButtonDisabled,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectPlan()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true),
      (_ctx.planType === 'freeBusiness')
        ? (_openBlock(), _createBlock(_component_gs_button, {
            key: 1,
            disabled: _ctx.isFreeBusinessPlanActive || _ctx.hasOwnPaidSubscriptionItem,
            type: "primary-v2",
            "full-width": "",
            size: "normal",
            onClick: _ctx.selectFreePlan
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.showTrial)
        ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_ctx.planType !== 'freeBusiness' ? _ctx.t('trial') : null), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}